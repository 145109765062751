exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-activity-scanning-and-modeling-tsx": () => import("./../../../src/pages/activity/scanning-and-modeling.tsx" /* webpackChunkName: "component---src-pages-activity-scanning-and-modeling-tsx" */),
  "component---src-pages-activity-shooting-ranges-tsx": () => import("./../../../src/pages/activity/shooting-ranges.tsx" /* webpackChunkName: "component---src-pages-activity-shooting-ranges-tsx" */),
  "component---src-pages-br-tsx": () => import("./../../../src/pages/br.tsx" /* webpackChunkName: "component---src-pages-br-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pikelhauba-tsx": () => import("./../../../src/pages/pikelhauba.tsx" /* webpackChunkName: "component---src-pages-pikelhauba-tsx" */),
  "component---src-pages-project-mechanic-tsx": () => import("./../../../src/pages/project/mechanic.tsx" /* webpackChunkName: "component---src-pages-project-mechanic-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */)
}

